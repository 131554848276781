import type { Component, App } from 'vue'
import HelloWorld from './HelloWorld.vue'
import Traning from './Training.vue'

const components: {
  [propName: string]: Component
} = {
  HelloWorld,
  Traning
}

export default {
  install: (app: App) => {
    for (const key in components) {
      app.component(key, components[key])
    }
  }
}
