
import { defineComponent } from 'vue'
import { WeatherInfo, WeatherInfoDetail } from '@/types/WeatherInfo'
// import { AxiosResponse } from 'axios'
// import request from '@/utils/axios'
// import Request from '@/utils/request'
import weatherService from '@/service/weatherService'
import { MRequestConfig } from '@/service/request/type'
import CITY_CODE_MAP from '@/consts/geo'

const API_KEY_WEATHER_FORECAST = 'a547bc7061b2480c923761a8d215020a'

export default defineComponent({
  name: 'Login',
  props: { text: String },
  data() {
    return {
      weatherData: [] as WeatherInfoDetail[],
      city: '' as string,
      w_city: '' as string,
      w_date: '' as string,
      message: '' as string
    }
  },
  methods: {
    onSubmit() {
      // 在方法中, this默认指向该方法所属的实例,可以使用this访问data中的属性或者其他方法
      // 千万注意, 方法不能使用箭头函数,因为箭头函数的this不是Vue实例, (例如 do: () => this.a)
      // 理由:箭头函数绑定了父级作用域的上下文，这种情况下this 将不会默认指向 Vue 实例,
      // this.a 运行时将会报错, 报错理由为a undefined(a没有被定义)
      if (this.message) {
        alert(this.message)
      } else {
        alert('没有任何输入.')
      }
    },
    getWeather() {
      // 封装axios的时候，已经设置了/api作为基地址，所以在此没有添加/api
      // // let httpURL = `/baidu/?city=${this.$route.params.city}`; //目标地址后接的详细地址
      // let httpURL = `?location=${this.city}`; // 目标地址后接的详细地址
      // request.get(httpURL).then((res) => { // https://blog.csdn.net/bryant953/article/details/79411688
      //     console.log(res);
      //     this.weatherData = res;
      //     this.city = this.weatherData.city;
      //     this.w_city = this.weatherData.city;
      //     this.w_date = this.weatherData.date;
      // });
      // request.get('test.csv').then((res) => {
      //     console.log(res);
      // });
      if (!this.city || !(this.city in CITY_CODE_MAP)) {
        this.city = '北京'
      }
      const city_code = CITY_CODE_MAP[this.city as keyof typeof CITY_CODE_MAP]
      console.log(city_code)

      const httpURL = `/v7/weather/3d?location=${city_code}&key=${API_KEY_WEATHER_FORECAST}` // 目标地址后接的详细地址
      // request.get(httpURL).then((res: AxiosResponse<WeatherInfo>) => {
      // Request.axiosInstance({ method: 'GET', url: httpURL }).then(
      const httpParams: MRequestConfig<WeatherInfo> = {
        url: httpURL
      } as MRequestConfig<WeatherInfo>
      weatherService.get<WeatherInfo>(httpParams).then((res) => {
        // https://blog.csdn.net/bryant953/article/details/79411688
        console.log(res)
        this.weatherData = res.daily
        this.w_city = this.city
        this.w_date = res.updateTime.substring(0, 16).replace('T', ' ')
      })
    }
  }
})
