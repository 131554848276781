import axios, { AxiosInstance } from 'axios'
import type { MRequestInterceptors, MRequestConfig } from './type'

import { ElLoading } from 'element-plus'
import { LoadingInstance } from 'element-plus/lib/components/loading/src/loading'

const DEFAULT_LOADING = true

class MRequest {
  instance: AxiosInstance
  interceptors?: MRequestInterceptors
  showLoading: boolean
  loading?: LoadingInstance

  constructor(config: MRequestConfig) {
    // 创建Axios实例
    this.instance = axios.create(config)
    // 保存基本信息
    this.showLoading = config.showLoading ?? DEFAULT_LOADING
    this.interceptors = config.interceptors
    // 拦截器封装 start
    // 外部实例拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )
    // 全局拦截器
    this.instance.interceptors.request.use(
      async (config) => {
        if (this.showLoading) {
          this.loading = ElLoading.service({
            lock: true,
            fullscreen: true,
            text: '正在请求数据....',
            background: 'rgba(0, 0, 0, 0.6)'
          })
          await new Promise((resolve) => setTimeout(resolve, 500))
        }
        return config
      },
      (err) => {
        return err
      }
    )
    this.instance.interceptors.response.use(
      (data) => {
        // 将loading移除
        this.loading?.close()
        // 这里做全局响应错误拦截 (服务器的 20X 但是可能有一个约定的key报错 那么我们就要判断这个key的值进行return data)+
        console.log('interceptors.response', data)
        return data
      },
      (err) => {
        // 将loading移除
        this.loading?.close()
        // 这里做全局响应错误拦截 (客户端的 40X 50X)
        return err
      }
    )
    // 拦截器封装 end
  }

  // 声明接口泛型是从外部导入 , 但是在type.ts中已经初始化赋值了AxiosResponse类型
  request<T>(config: MRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 1.单个请求对请求config的处理
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }

      // 2.判断是否需要显示loading
      if (config.showLoading === false) {
        this.showLoading = config.showLoading
      }

      this.instance
        .request<any, T>(config)
        .then((res) => {
          // 1.单个请求对数据的处理
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          // 2.将showLoading设置true, 这样不会影响下一个请求
          this.showLoading = DEFAULT_LOADING

          // 3.将结果resolve返回出去
          resolve(res)
        })
        .catch((err) => {
          // 将showLoading设置true, 这样不会影响下一个请求
          this.showLoading = DEFAULT_LOADING
          reject(err)
          return err
        })
    })
  }

  get<T>(config: MRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'GET' })
  }

  post<T>(config: MRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' })
  }

  delete<T>(config: MRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' })
  }

  patch<T>(config: MRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PATCH' })
  }
}

export default MRequest
