import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from '@/components'
// 导入请求拦截器
import Request from '@/utils/request'
import VueAxios from 'vue-axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)
app
  .use(store)
  .use(ElementPlus)
  .use(globalComponents)
  .use(router)
  .use(VueAxios, Request.init())
app.mount('#app')
