import MRequest from './request/index'
import { MRequestConfig } from './request/type'
import { BASE_URL, TIME_OUT } from './request/weather.config'

// 实例化HYRequest 我们可以多个实例化多个接口 根据不同的config传进去
const getWeatherRequest = new MRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor(config) {
      const token = 'x'
      if (token) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config.headers.Authorization = `Bearer +${token}`
      }
      console.log('requestInterceptor')
      return config
    },
    requestInterceptorCatch(err) {
      return err
    },
    responseInterceptor(res) {
      console.log('responseInterceptor')
      return res.data
    },
    responseInterceptorCatch(err) {
      return err
    }
  }
} as MRequestConfig)

export default getWeatherRequest
